<template>
  <div class="page_nav_box">
    <div class="block"></div>
    <div class="filter_block"></div>
    <div class="page_nav">
      <div class="wrap nav">
        <div class="logo">something~</div>
        <div class="nav_box">
          <div v-for="(item, index) in titles" :key="index" class="section" :class="{forbidden: item.forbidden, selected: item.selected}" v-show="(userId === 41 && item.name === '日记本') || item.name !== '日记本'" @click="toUrl(item)">
            {{item.name}}
          </div>
        </div>
        <div class="person" v-show="userName">
          <!-- <i class="iconfont icontouxiang"></i> -->
          <i class="iconfont icontouxiang4"></i>
          <div class="person_hover">
            <div class="edit_person">更改信息</div>
            <div class="out" @click="out">退出登录</div>
          </div>
        </div>
        <div class="go_add" v-show="!userName" @click="goAdd">登录/注册</div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'commonNav',
  components: {
  },
  data () {
    return {
      titles: [
        {
          name: '首页',
          path: '/',
          forbidden: false,
          selected: false
        }, {
          name: '聊天室',
          path: '/chatRoom',
          forbidden: true,
          selected: false
        }, {
          name: '留言板',
          path: '/messageBoard',
          forbidden: false,
          selected: false
        }, {
          name: '照片墙',
          path: '/photoWall',
          forbidden: false,
          selected: false
        }, {
          name: '日记本',
          path: '/daily',
          forbidden: false,
          selected: false
        }, {
          name: '书摘地',
          path: '/digest',
          forbidden: false,
          selected: false
        }, {
          name: '小玩意',
          path: '/play',
          forbidden: false,
          selected: false
        }]
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      console.log('重新渲染没-------', this.userName, this.userId)
      console.log('------', this.$route.path)
      this.$bus.$on('getUserName', (val) => {
        this.userName = val
      })
      this.titles.map(item => {
        if (item.name != "首页") {
          item.selected = this.$route.path.indexOf(item.path) === -1 || item.name === '首页' ? false : true
        } else {
          item.selected = this.$route.path === item.path ? true : false
        }
        // console.log(item.path, this.$route.path)
      })
    },
    out () {
      this.$bus.$emit('getUserName', null)
      this.$bus.$emit('getUserId', null)
      localStorage.removeItem('chatUser')
      this.userName = ''
      this.$router.push({
        path: '/addUser?noAuto=1'
      })
    },
    goAdd () {
      this.$router.push({
        path: '/addUser'
      })
    },
    toUrl (item) {
      if (item.forbidden) return
      this.$router.push({
        path: `${item.path}`
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
