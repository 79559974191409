<template>
  <div id="app">
    <common-nav ref="commonNav" />
    <router-view/>
  </div>
</template>

<script>
import commonNav from '@/component/commonNav'
  export default{
    name: 'app',
    components: {
      commonNav
    },
    data() {
      return {

      }
    },
    mounted() {
    },
    watch: {
      $route(val) {
        console.log('app监听地址栏变化----', val)
        this.$refs.commonNav.init()
      }
    }
  }
</script>

<style>
  #app{
    user-select: none;
  }
</style>
