<template>
  <div id="app">
    <!-- <common-nav /> -->
    <div class="page_main">
      <div class="wrap main">
      </div>
    </div>
    <div class="page_footer"></div>
  </div>
</template>

<script>
// import commonNav from '@/component/commonNav'
export default {
  name: 'App',
  components: {
    // commonNav
  },
  mounted() {
    
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
  @import './index.scss'
</style>
