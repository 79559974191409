import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import lodash from 'lodash'


const bus = new Vue()

Vue.prototype.$bus = bus

Vue.config.productionTip = false
Vue.prototype.lodash = lodash
Vue.use(VueAxios, axios)

console.log('是不是刚运行就走了')
Vue.prototype.userName = JSON.parse(localStorage.getItem('chatUser')) ? JSON.parse(localStorage.getItem('chatUser')).userName : ''
Vue.prototype.userId = JSON.parse(localStorage.getItem('chatUser')) ? JSON.parse(localStorage.getItem('chatUser')).userId : ''

Vue.prototype.$bus.$on('getUserName', (val) => {
  Vue.prototype.userName = val
  console.log('放进去没---', Vue.prototype.userName)
})

Vue.prototype.$bus.$on('getUserId', (val) => {
  Vue.prototype.userId = val
  console.log('放进去没---', Vue.prototype.userId)
})

const isMobile = () => {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true // 手机端
  } else {
    return false // alert('PC端')
  }
}

const isWeiXin = () => {
  var ua = window.navigator.userAgent.toLowerCase()
  if (ua.indexOf('micromessenger') > -1) {
    return true // 是微信端
  } else {
    return false
  }
}

// rem
const setHtmlFontSize = () => {
  const htmlDom = document.getElementsByTagName('html')[0]
  let htmlWidth = document.documentElement.clientWidth || document.body.clientWidth
  console.log('htmlWidth-------', htmlWidth, isMobile(), isWeiXin())
  if (htmlWidth >= 750) {
    htmlWidth = 750
  }
  if (htmlWidth <= 320) {
    htmlWidth = 320
  }
  htmlDom.style.fontSize = `${htmlWidth / 7.5}px`
}
window.onresize = setHtmlFontSize
setHtmlFontSize()


new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
