import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../pages/index.vue'


const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/addUser',
    name: 'addUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../pages/addUser')
  },
  {
    path: '/userManage',
    name: 'userManage',
    component: () => import('../pages/userManage')
  },
  {
    path: '/digest',
    name: 'digest',
    component: () => import('../pages/digest')
  },
  {
    path: '/daily',
    name: 'daily',
    component: () => import('../pages/daily')
  },
  {
    path: '/messageBoard',
    name: 'messageBoard',
    component: () => import('../pages/messageBoard')
  },
  {
    path: '/chatRoom',
    name: 'chatRoom',
    component: () => import('../pages/chatRoom')
  },
  {
    path: '/photoWall',
    name: 'photoWall',
    component: () => import('../pages/photoWall')
  },
  {
    path: '/play',
    name: 'play',
    component: () => import('../pages/play'),
    children: [
      {
        path: 'recorder',
        component: () => import('../pages/play/recorder'),
      },{
        path: 'recorderForC34',
        component: () => import('../pages/play/recorderForC34'),
      },{
        path: 'shortMemory',
        component: () => import('../pages/play/shortMemory'),
      },{
        path: 'todoList',
        component: () => import('../pages/play/todoList'),
      },{
        path: 'lookForCM',
        component: () => import('../pages/play/lookForCM'),
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  // base: '/dist',
  routes: [...routes]
})

export default router
